<style></style>
<template>
  <div id="mainContent" class="clearfix row">
    <div class="textCenter" style="min-height: 40px; position: relative">
      <Headerv2 title="Invite your Partner" />
      <!-- <div class="text-xl pt-4 text-black">Invite your Partner</div> -->
    </div>
    <div id="flexDiv row" class="clearfix" style="min-height: 500px">
      <div class="col-xs-12">
        <p class="alert-mira mt-3 mb-3">
          Send your partner a link to create a HomeRun Account and connect it to
          your own.
        </p>
        <div>
          <div class="relative">
            <DropdownSelection
              keyProp="id"
              v-if="schools.length > 0 && school_id"
              single
              small
              class="smallDropdown mb-4"
              name="name"
              default_label="Select School"
              :blueTheme="true"
              :items="schools"
              :default_value="school_id"
              @updated="changeSchool"
            />
          </div>
          <span class="text-left float-left font-medium text-sm"
            >Select Children</span
          ><br />
          <div>
            <div
              name="fade"
              tag="div"
              v-if="sortedChildren.length > 0 && school_id"
            >
              <div
                :key="student.id"
                v-for="student in sortedChildren"
                class="studentContainer"
                :class="{ disabled: !!student.disabled }"
              >
                <img
                  class="smallAvatar"
                  :class="{
                    selected: student.selected ? true : false,
                  }"
                  @click="toggle(student.id)"
                  :src="child_avatars_url + student.profile_photo"
                />
                <span
                  v-if="student.disabled"
                  class="circleWrapper"
                  :class="student.pendingInvite ? 'pendingInvite' : ''"
                >
                  <span
                    v-if="student.pendingInvite"
                    class="fas fa-clock"
                  ></span>
                  <span
                    v-if="student.disabled && !student.pendingInvite"
                    class="fal fa-check"
                  ></span>
                </span>
                <span class="text-xs" :title="student.first_name">{{
                  student.first_name
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <van-field
          id="text_input"
          name="Email"
          data-cy="text_input"
          placeholder="Partner's email"
          type="text"
          v-model="email"
          maxlength="50"
          class="roundInput !border-gray-400 mb-2"
        ></van-field>
      </div>
      <div class="">
        <div class="col-xs-12 flowBottomButtonDiv" style="flex-grow: 0">
          <van-button
            type="info"
            data-cy="go_back_btn"
            class="greyBtn"
            @click="$router.go(-1)"
            plain
            >Go Back</van-button
          >
          <van-button
            type="info"
            name="button"
            data-cy="submit"
            class="blackBtn"
            @click="sendInvitation()"
            :disabled="
              !email ||
              !email.includes('@') ||
              !email.includes('.') ||
              selectedChildren.length == 0
            "
            >Invite</van-button
          >
        </div>
      </div>
      <div>
        <div :key="invitation.id" v-for="invitation in invitations">
          <van-button
            name="button"
            data-cy="revoke"
            class="!bg-red-500 !text-white"
            :loading="loading"
            @click="cancelInvitation(invitation.id)"
            >({{ invitation.email }}) - Revoke <i class="fas fa-times"></i
          ></van-button>
        </div>
      </div>
      <!-- <div id="bottomLogo" class="text-center">
        <img
          src="@/assets/wireframe_logo.svg"
          style="vertical-align: baseline"
        />
      </div> -->
      <modal
        name="miEmailNotFoundModal"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
        data-cy="email_already_exists_modal"
      >
        <div class="">
          <div
            class="col-xs-12"
            style="margin-bottom: 50px; margin-top: 50px; text-align: center"
          >
            <MiFlowHeader
              page_title="Sorry, we couldn't find that email."
              :logo="null"
            />
            <p class="mialert lightRed">
              We couldn't find this email on your school's system. Please check
              that you have typed the email correctly, or contact your school if
              you believe there is an error
            </p>
          </div>
        </div>
        <div class="col-xs-12">
          <van-button
            type="info"
            data-cy="email_already_exists_modal_close_btn"
            @click="hideModal('miEmailNotFoundModal')"
            class="greyBtn"
            plain
            >Go Back</van-button
          >
        </div>
      </modal>
    </div>
  </div>
</template>
<script>
import ApiService from "@/services/ApiService";
import Header from "@/components/Header";
import ModalHeader from "@/components/ModalHeader";
import MiFlowHeader from "@/components/registration_mi/MiFlowHeader.vue";
import Headerv2 from "@/components/Headerv2.vue";
import DropdownSelection from "@/components/DropdownSelection";

export default {
  name: "invitePartner",
  components: {
    MiFlowHeader,
    Headerv2,
    DropdownSelection,
    // Header,
    // ModalHeader,
  },
  data() {
    return {
      number: "",
      email: "",
      child_avatars_url: "https://s3-eu-west-1.amazonaws.com/home-run/",
      children: [],
      schools: [],
      invitations: [],
      pendingParents: [],
      childToInvite: [],
      loading: false,
      selectedChildren: [],
      school_id: null,
    };
  },
  computed: {
    sortedChildren: function () {
      return this.children
        .filter((stu) => stu.organization_id == this.school_id)
        .sort((a, b) => a.disabled - b.disabled)
        .sort((a, b) => {
          return (
            (a.pendingInvite != null) - (b.pendingInvite != null) ||
            b.pendingInvite - a.pendingInvite
          );
        });
    },
  },
  methods: {
    toggle(studentId) {
      const currentChild = this.children.filter((stu) => stu.id == studentId);
      if (currentChild.length == 0) {
        return;
      }
      if (currentChild[0].parents.length > 1) {
        this.$notify({
          message:
            "You've already invited a partner for " +
            currentChild[0].first_name,
          type: "warning",
        });
        return;
      }

      if (currentChild[0].pendingInvite) {
        this.$notify({
          message: "You've already have a pending invite",
          type: "warning",
        });
        return;
      }

      this.children.map((stu) => {
        if (stu.id == studentId) {
          stu.selected = !stu.selected;
        }
      });

      if (this.selectedChildren.indexOf(studentId) == -1) {
        this.selectedChildren.push(studentId);
      } else {
        this.selectedChildren = this.selectedChildren.filter(
          (id) => id != studentId
        );
      }
      this.$forceUpdate();
    },
    getInvitations() {
      this.pendingParents = [];
      this.childToInvite = [];

      ApiService.userApi
        .allInvitations()
        .then((response) => {
          this.$store.dispatch("loading", false);
          this.invitations = response.data.invitations;
          const pendingStudents = [];
          for (var i = 0; i < this.invitations.length; i++) {
            var _singleInvitation = this.invitations[i];
            _singleInvitation.children = JSON.parse(_singleInvitation.children);
            for (var y = 0; y < _singleInvitation.children.length; y++) {
              const studentID = _singleInvitation.children[y];
              pendingStudents.push(studentID);

              // var childKey = "c" + _singleInvitation.children[y];
              // this.pendingParents[childKey] = _singleInvitation.phone_number;
              // this.childToInvite[childKey] = _singleInvitation.id;
            }
          }
          this.children = JSON.parse(JSON.stringify(this.children));
          const schoolsWithChildren = [];
          this.children.forEach((itm) => {
            // if (itm.parents.length == 0 && !this.pendingParents["c" + itm.id]) {
            //   itm.selected = true;
            // }
            if (schoolsWithChildren.indexOf(itm.school_id) == -1) {
              schoolsWithChildren.push(itm.school_id);
            }
            if (
              itm.parents.length > 1 ||
              pendingStudents.indexOf(itm.id) > -1
            ) {
              itm.disabled = true;
              if (pendingStudents.indexOf(itm.id) > -1) {
                itm.pendingInvite = true;
              }
            } else {
              itm.disabled = false;
            }
          });
          this.children = JSON.parse(JSON.stringify(this.children));
          this.schools = this.schools.filter(
            (school) => schoolsWithChildren.indexOf(school.id) > -1
          );
          if (this.schools.length > 0) {
            this.changeSchool([this.schools[0].id]);
          }
          this.$forceUpdate();
        })
        .catch(() => this.$store.dispatch("loading", false));
    },
    getUserSchools() {
      this.$store.dispatch("loading", true);
      ApiService.userApi.getUserSchools().then((response) => {
        this.schools = response.data.schools;
        if (this.schools.length > 0) {
          this.school_id = response.data.schools[0].id;
        }
        this.children = response.data.children;
        this.getInvitations();
      });
    },
    sendInvitation() {
      var childrenIds = [];
      for (var i = 0; i < this.children.length; i++) {
        if (this.children[i].selected) {
          childrenIds.push(this.children[i].id);
        }
      }

      if (childrenIds.length === 0) {
        this.$notify({
          message: "Please select a child/children to continue",
          type: "warning",
        });
        return;
      }

      var payload = {
        number: this.number,
        email: this.email,
        childrenIds: childrenIds,
      };

      ApiService.userApi
        .createInvitation(payload, childrenIds)
        .then((response) => {
          if (response.status == 200) {
            this.$notify({
              message: "Invitation sent succesfully.",
              type: "success",
            });

            for (var i = 0; i < this.children.length; i++) {
              if (this.children[i].selected) {
                this.children[i].selected = false;
              }
            }
            this.getInvitations();
          }
        })
        .catch((e) => {
          this.$modal.show("miEmailNotFoundModal");
          // this.$notify({
          //   message: "Error occured. Try again later.",
          //   type: "danger",
          // });
        });
    },
    cancelInvitation(id) {
      ApiService.userApi.cancelInvitation(id).then(() => {
        this.$notify({
          message: "Removed",
          type: "success",
        });
        this.getInvitations();
      });
    },
    showModal() {
      let childrenIds = [];
      for (var i = 0; i < this.children.length; i++) {
        if (this.children[i].selected) {
          childrenIds.push(this.children[i].id);
        }
      }
      this.$modal.show("confirmModal");
    },
    hideModal() {
      this.$modal.hide("miEmailNotFoundModal");
    },
    changeSchool(id) {
      if (id == "all") {
        return;
      }

      this.school_id = id[0];
      const selectedChildren = [];
      // this.selectedChildren = this.selectedChildren.filter(
      //   (id) => id != this.studentId
      // );
      this.children.forEach((ch) => {
        ch.selected =
          ch.school_id == this.school_id && ch.disabled == false ? true : false;
        if (ch.school_id == this.school_id && ch.disabled == false) {
          selectedChildren.push(ch.id);
        }
      });
      this.selectedChildren = selectedChildren;
    },
  },
  mounted() {
    this.getUserSchools();
  },
};
</script>
<style scoped>
img.smallAvatar {
  margin: 5px;
  margin-bottom: 0px;
  border: 2px solid transparent;
  cursor: pointer;
  border-radius: 35px;
  padding: 1px;
}
img.smallAvatar.selected {
  padding: 2px;
  border: 2px solid #2fab69;
}
.smallAvatar {
  width: 70px;
  height: 70px;
}
.studentContainer.disabled img {
  cursor: default;
  opacity: 0.3;
}
.studentContainer {
  display: inline-block;
  margin-bottom: 5px;
  overflow: hidden;
  max-width: 75px;
  position: relative;
}
.studentContainer span {
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 60px;
  overflow: hidden;
  display: inline-block;
}
span.circleWrapper {
  position: absolute;
  width: 22px;
  height: 22px;
  border-radius: 15px;
  padding-top: 4px;
  top: 55px;
  right: 5px;
  background: #009900;
}
span.circleWrapper.pendingInvite {
  background: #ff9900;
}
span.circleWrapper span {
  color: white;
}
</style>
