<template>
  <div id="app">
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link
      href="https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500&display=swap"
      rel="stylesheet"
    />
    <transition name="fade2" mode="out-in">
      <router-view></router-view>
    </transition>
    <v-dialog />
  </div>
</template>
<script>
import jwtDecodefrom from "jwt-decode";
export default {
  name: "app",
  data() {
    return {
      lastMessage: null,
    };
  },
  methods: {
    onMessage(data) {
      const currentRoute = this.$router.currentRoute.name;
      var jsonData =
        typeof data.data != "undefined" ? JSON.parse(data.data) : data;

      if (typeof jsonData.menu != "undefined") {
        if (jsonData.menu == "social" && currentRoute == "home") {
          // CommonDataService.setToken(jsonData.token);
          this.$router.push({
            name: "home",
            query: { refresh: Math.random() },
          });
          // this.$store.dispatch("setToken", jsonData.token);
          // $rootScope.$broadcast("reloadHome", { refreshCount: true });
        } else if (jsonData.menu == "social") {
          // this.$router.push({ name: "home" });
          // $rootScope.$broadcast("refreshStatusCount");
        } else if (jsonData.menu == "journeys") {
          // $state.go("main.trackMyChildren", { childId: 0 });
        }
      } else if (typeof jsonData.data != "undefined") {
        if (jsonData.type == "imageUploaded") {
          // $rootScope.$broadcast("imageUploaded", {
          //   id: jsonData.id,
          //   url: jsonData.imageUrl
          // });
        }
      } else {
        if (!this.lastMessage) {
          this.lastMessage = "lgoinViaMobileApp";
        } else {
          return;
        }

        this.$store.dispatch("loginViaMobileApp", jsonData);

        try {
            const decoded = jwtDecodefrom(jsonData.token);
            if(decoded.user_role_id){
              this.$store.dispatch("setUserRoleId", decoded.user_role_id);
            }
          } catch (error) {
            if (jsonData && jsonData.user && jsonData.user.user_role_id) {
              this.$store.dispatch("setUserRoleId", jsonData.user.user_role_id);
            }
          }

        this.$store.dispatch("setToNative", true);

        var versionArray = [];
        if (jsonData.version) {
          versionArray = jsonData.version.split(".");
        }

        if (
          !jsonData.version ||
          (jsonData.version &&
            versionArray.length > 0 &&
            parseInt(versionArray[0]) < 6)
        ) {
          setTimeout(() => {
            this.$router.push({ name: "upgrade" });
          }, 400);
        } else {
          setTimeout(() => {
            this.$router.push({ name: "home" });
          }, 400);
        }
      }
    },
  },
  created() {
    window.messageFromNative = (data) => {
      this.onMessage(data);
    };
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background-color: #e4e4e4;
}

body,
html {
  background-color: #e4e4e4;
}

.fade2-enter-active,
.fade2-leave-active {
  transition-duration: 0.35s;
  transition-property: opacity, transform;
  transition-timing-function: ease;
}

.fade2-enter,
.fade2-leave-active {
  opacity: 0;
  transform: translate(-10px, 0);
}
.fade2-leave-to {
  transform: translate(10px, 0);
}

.slide-leave-active,
.slide-enter-active {
  transition: 0.25s;
}
.slide-enter {
  transform: translate(20%, 0);
  opacity: 1;
}
.slide-leave-to {
  transform: translate(-20%, 0);
  opacity: 0;
}
/* Enter and leave animations can use different */
/* durations and timing functions.              */
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
  -webkit-transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  -webkit-transform: translateX(10px);
  opacity: 0;
}
</style>
