<template>
  <div>
    <div class="mydetails" style="border: none">
      <div class="row">
        <div class="col-xs-12">
          <Headerv2 title="Let's get some details" :showBack="false" />
        </div>
      </div>
      <form name="form" @submit="submit()">
        <div class="col-xs-12 form-row">
          <div class="form-row__title">Select a profile picture</div>
          <div
            class="avatar"
            @click="openParentAvatarModal"
            data-cy="open_avatar_modal"
          >
            <span class="add-icon" v-if="!selectedImage"
              ><i class="fa fa-plus" aria-hidden="true"></i
            ></span>
            <img
              v-if="selectedImage"
              :src="selectedImage"
              alt
              data-cy="selected_avatar_image"
            />
            <span
              v-if="!selectedImage"
              style="
                color: #e61180;
                font-size: 11px;
                position: relative;
                display: inline-block;
              "
            >
              <i class="user-icon fa fa-user-circle" aria-hidden="true" />
            </span>
          </div>
          <div
            class="col-xs-12 text-danger"
            v-if="showErrors && !selectedImage"
          >
            Avatar/Image is required
          </div>
          <div class="form-row">
            <div v-if="term" class="termtext clearfix" :class="getTermClass">
              Do you need to update your address ?
            </div>
            <div class="form-row__title">Home address</div>
            <div>
              <div class="mialert lightGreen">
                Select your address from the drop down below.
                <br />
                You will then see a map of the correct location.
                <button class="link" @click.prevent="showModal('addressModal')">
                  Why do we need this?
                </button>
              </div>
              <AddressFlow
                :defaultMarker="defaultMarker"
                :postcode="postcode"
                :currentAddress="currentAddress"
                @newAddress="setAddress"
              />
            </div>
            <div style="clear: both"></div>
            <p
              class="text-center colorBlue"
              v-if="showMap"
              style="margin-top: 10px"
            >
              Please use the pin to locate your home.
            </p>
          </div>

          <div
            class="shaddowBlock"
            v-if="showParentOptions"
            data-cy="parent_options"
          >
            <!-- <div v-if="parentData" class="form-row">
              <div class="form-row__title">Are you a member of the PTA?</div>
              <div>
                <van-radio-group
                  v-model="parentData.is_pta"
                  direction="horizontal"
                >
                  <van-radio :name="1" data-cy="parent_pta_yes">Yes</van-radio>
                  <van-radio :name="0" data-cy="parent_pta_no">No</van-radio>
                </van-radio-group>
              </div>
              <div
                class="col-xs-12 text-danger"
                v-if="showErrors && parentData.is_pta == null"
              >
                Please specify if you are a PTA member
              </div>
            </div> -->
            <div v-if="parentData" class="form-row">
              <div v-if="term" class="termtext" :class="getTermClass">
                Have you started journey sharing ?
              </div>
              <div>
                <div class="form-row__title">
                  Do you share your school journeys with other parents?
                </div>
              </div>
              <div>
                <van-radio-group
                  v-model="parentData.sharing_journey"
                  direction="horizontal"
                >
                  <van-radio :name="1" data-cy="parent_sharing_journey_yes"
                    >Yes</van-radio
                  >
                  <van-radio :name="0" data-cy="parent_sharing_journey_not"
                    >No</van-radio
                  >
                </van-radio-group>
              </div>
              <div class="clearfix"></div>
              <div
                class="col-xs-12 text-danger"
                v-if="showErrors && parentData.sharing_journey == null"
              >
                Please specify if you share school journeys
              </div>
            </div>

            <div
              v-if="term"
              class="form-row"
              :class="getTermClass"
              data-cy="term_message"
            >
              <div class="form-row__title">
                Have your journey preferences changed ?
              </div>
            </div>
            <div v-if="parentData" class="form-row preferences">
              <div>
                <div class="form-row__title">
                  Your school journey preferences
                </div>
              </div>
              <div class="mialert lightGreen">
                Would you like to share your school run journey?
                <div
                  @click.prevent="
                    showModal('journeyHelpModal');
                    tempLabel = 'parents';
                  "
                  data-cy="show_parent_journey_help_modal"
                >
                  <button class="link ml-2 m-auto">Learn more</button>
                </div>
              </div>
              <div class="preferences-row">
                <Checkbox
                  id="parentInterestedInPooling"
                  v-model="parentData.interestedInPooling"
                  data-cy="interested_pooling_checkbox"
                  @change="
                    toggleJourneyPreference('parent', 'interestedInPooling')
                  "
                ></Checkbox>

                <div>
                  <label
                    class="fsize14"
                    for="parentInterestedInPooling"
                    data-cy="parent_interestedInPooling"
                  >
                    I am open to being contacted by parents from my school
                    network to discuss sharing school journeys.
                  </label>
                </div>
              </div>
              <div class="preferences-row">
                <Checkbox
                  id="parentTravelBuddies"
                  v-model="parentData.travel_buddies"
                  data-cy="travel_buddies_checkbox"
                  @change="toggleJourneyPreference('parent', 'travel_buddies')"
                ></Checkbox>
                <div>
                  <label
                    class="fsize14"
                    for="parentTravelBuddies"
                    data-cy="parent_travel_buddies"
                  >
                    I would be interested in finding suitable travel buddies to
                    accompany my child on their independent journey to school.
                  </label>
                </div>
              </div>
              <div class="preferences-row">
                <Checkbox
                  id="parentNotInterested"
                  v-model="parentData.notInterestedInPooling"
                  data-cy="not_interested_checkbox"
                  @change="
                    toggleJourneyPreference('parent', 'notInterestedInPooling')
                  "
                ></Checkbox>
                <div>
                  <label
                    class="fsize14"
                    for="parentNotInterested"
                    data-cy="parent_notInterested"
                    >I am not interested in either of the above.</label
                  >
                </div>
              </div>
              <div
                class="col-xs-12 text-danger"
                data-cy="show_parents_errors"
                v-if="
                  showErrors &&
                  !parentData.notInterestedInPooling &&
                  !parentData.travel_buddies &&
                  !parentData.interestedInPooling
                "
              >
                Please specify your journey preferences
              </div>
            </div>
          </div>
          <!-- STAFF DATA STARTS HERE -->
          <div
            class="shaddowBlock staff-section"
            v-if="showStaffOptions"
            data-cy="staff_options"
          >
            <h3 class="staff-options__title" data-cy="staff_options_header">
              Staff Options
            </h3>
            <div v-if="staffData" class="form-row" data-cy="staff_options">
              <div class="form-row">
                <div class="form-row__title">Staff role at my school</div>

                <div
                  v-if="staffData.is_staff"
                  class="col-xs-12 clearfix"
                  style="clear: both"
                ></div>
                <div>
                  <van-radio-group
                    v-model="staffData.staff_type"
                    data-cy="staff_type_radio_group"
                  >
                    <van-radio
                      :name="null"
                      data-cy="staff_type_none"
                      style="margin-bottom: 15px"
                      >Not applicable
                    </van-radio>
                    <van-radio
                      name="TEACHER"
                      data-cy="staff_type_teacher"
                      style="margin-bottom: 15px"
                      >Teacher</van-radio
                    >
                    <van-radio
                      name="HEAD_TEACHER"
                      data-cy="staff_type_head_teacher"
                      style="margin-bottom: 15px"
                      >Head Teacher</van-radio
                    >
                    <van-radio
                      name="SCHOOL_OFFICE"
                      data-cy="staff_type_school_office"
                      style="margin-bottom: 15px"
                      >School office</van-radio
                    >
                    <!-- <van-radio
                      name="SUPPORT_STAFF"
                      data-cy="staff_type_support_staff"
                      style="margin-bottom: 15px"
                      >Support staff</van-radio
                    >
                    <van-radio
                      name="TEACHING_STAFF"
                      data-cy="staff_type_teaching_staff"
                      style="margin-bottom: 15px"
                      >Teaching staff
                    </van-radio> -->
                  </van-radio-group>
                </div>
              </div>
              <div v-if="staffData && staffSchoolAddresses.length > 0">
                <div class="form-row">
                  <div class="form-row__title">
                    Select your school destination
                  </div>

                  <div class="form-dropdown-wrapper">
                    <select
                      required
                      class="form-select van-cell__value colorBlack mb-3"
                      v-model="staffData.organization_address_id"
                      placeholder="Select your school destination"
                    >
                      <option disabled selected value="null">
                        -- select your school destination --
                      </option>
                      <option
                        :key="school.id"
                        :value="school.id"
                        v-for="school in staffSchoolAddresses"
                      >
                        {{ school.name }}
                      </option>
                    </select>
                  </div>
                  <!-- <van-radio-group
                    v-model="staffData.organization_address_id"
                    data-cy="staff_destination_radio_group"
                  >
                    <van-radio
                      :key="school.id"
                      :name="school.id"
                      v-for="school in staffSchoolAddresses"
                      style="margin-bottom: 15px"
                      >{{ school.name }}</van-radio
                    >
                  </van-radio-group> -->
                </div>
                <div
                  class="col-xs-12 text-danger"
                  v-if="showErrors && !staffData.organization_address_id"
                  data-cy="show_empty_staff_destionation_id_message"
                >
                  Please select which school location you travel to
                </div>
              </div>
            </div>
            <div v-if="staffData" class="form-row sharing_journey">
              <div class="form-row">
                <div class="form-row__title">
                  Do you share your school journeys with other employees?
                </div>

                <div>
                  <van-radio-group
                    v-model="staffData.sharing_journey"
                    direction="horizontal"
                  >
                    <van-radio :name="1" data-cy="staff_sharing_journey_yes"
                      >Yes</van-radio
                    >
                    <van-radio :name="0" data-cy="staff_sharing_journey_no"
                      >No</van-radio
                    >
                  </van-radio-group>
                </div>
                <div
                  class="col-xs-12 text-danger"
                  v-if="showErrors && staffData.sharing_journey == null"
                >
                  Please specify if you share school journeys
                </div>
              </div>
            </div>

            <div v-if="staffData" class="form-row preferences">
              <div class="form-row">
                <div v-if="term" class="termtext" :class="getTermClass">
                  Have you started journey sharing ?
                </div>
                <div v-if="staffData" class="form-row__title">
                  Staff School Journey Preferences
                </div>

                <div class="mialert lightGreen">
                  N.B Optional HomeRun Journey Sharing features show staff from
                  the same school network the approximate distance they live
                  from each other.
                  <button
                    class="link"
                    @click.prevent="
                      showModal('journeyHelpModal');
                      tempLabel = 'staff';
                    "
                    data-cy="show_staff_journey_help_modal"
                  >
                    See more
                  </button>
                </div>

                <div class="preferences-row">
                  <Checkbox
                    id="staffPooling"
                    v-model="staffData.interestedInPooling"
                    @change="
                      toggleJourneyPreference('staff', 'interestedInPooling')
                    "
                    data-cy="interested_inpooling"
                  >
                  </Checkbox>
                  <div>
                    <label class="fsize14" for="staffPooling">
                      I am open to being contacted by staff members from my
                      school network to discuss sharing school journeys.
                    </label>
                  </div>
                </div>

                <div class="preferences-row">
                  <Checkbox
                    id="staffPoolingNot"
                    v-model="staffData.notInterestedInPooling"
                    @change="
                      toggleJourneyPreference('staff', 'notInterestedInPooling')
                    "
                    data-cy="staff_not_interested_inpooling"
                  >
                  </Checkbox>

                  <div>
                    <label class="fsize14" for="staffPoolingNot"
                      >I am not interested in journey sharing.</label
                    >
                  </div>
                </div>
                <div
                  class="col-xs-12 text-danger"
                  v-if="
                    showErrors &&
                    !staffData.notInterestedInPooling &&
                    !staffData.interestedInPooling
                  "
                  data-cy="show_staff_journey_error_message"
                >
                  Please specify your journey preferences
                </div>
              </div>
            </div>
          </div>
          <div class="text-center mt-6 mb-10">
            <button
              class="btn btn-black"
              data-cy="submitButton"
              @click.prevent="saveAddress"
            >
              Continue
            </button>
          </div>
        </div>
      </form>
      <!-- Modal for Journey Help -->
      <modal
        class="modal-flow"
        name="journeyHelpModal"
        id="modalOverlay"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
        data-cy="journey_help_modal"
      >
        <i
          class="modal-close fa-solid fa-xmark fa-2xl"
          @click="hideModal('journeyHelpModal')"
          data-cy="journey_help_modal_close_btn"
          aria-hidden="true"
        ></i>
        <div>
          <p>
            Your address is never shared or displayed on a map. Only your
            approximate distance from other
            <strong>{{ tempLabel }}</strong> of your school network is
            displayed. See how this looks in the app below.
          </p>
          <div class="text-center marginVertical20">
            <img
              v-if="tempLabel == 'staff'"
              src="@/assets/milesFromMeExampleStaff.png"
              style="max-width: 400px; width: 100%"
            />
            <img
              v-if="tempLabel == 'parents'"
              src="@/assets/milesFromMeExample.jpg"
              style="max-width: 100%"
            />
          </div>
          <p>
            If you do not want this information to be displayed, select the 'I
            am not interested in either of the above' option for your journey
            preference.
          </p>
        </div>
      </modal>
      <modal
        class="modal-flow"
        name="addressModal"
        id="modalOverlay"
        :adaptive="true"
        :height="'auto'"
        :scrollable="true"
        data-cy="address_help_modal"
      >
        <i
          class="modal-close fa-solid fa-xmark fa-2xl"
          @click="hideModal('addressModal')"
          data-cy="close_address_help_modal"
        ></i>
        <p class="text-center">
          We collect home addresses to empower schools to create customized
          travel solutions for parents. Your address data is vital for schools
          to efficiently plan and optimize routes, enhancing the travel
          experience for your child. We do not share your home address with
          anybody.
        </p>
      </modal>
    </div>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";
import Headerv2 from "../../components/Headerv2.vue";

import Uploader from "@/components/modals/UploaderFlow";
import AddressFlow from "@/components/modals/AddressFlow";
import Checkbox from "vue-material-checkbox";

export default {
  name: "detailsFlow",
  components: {
    Checkbox,
    Headerv2,
    AddressFlow,
  },
  data() {
    return {
      selectedImage: null,
      showErrors: false,
      addressSelection: [],
      staffSchoolAddresses: [],
      showAddressInput: false,
      showMap: false,
      parentData: {},
      staffData: {
        organization_address_id: "null",
      },
      showAvatarModal: false,
      // showModal: false,
      showJourneyHelpModal: false,
      postcode: "",
      tempLabel: "",
      address: {},
      defaultMarker: {},
      showParentOptions: false,
      showStaffOptions: false,
      currentAddress: null,
    };
  },
  computed: {
    term() {
      return this.$store.state.general.term;
    },
    getTermClass() {
      return "termcolor_" + this.term;
    },
    isUserStaff() {
      return (
        this.$store.state.general?.user?.is_staff ||
        this.$store.state.user?.is_staff
      );
    },
  },
  methods: {
    openParentAvatarModal() {
      this.$modal.show(
        Uploader,
        {
          options: {
            profilePicture: true,
          },
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          adaptive: true,
          class: "modal-flow",
          // pivotY: 0
        },
        {
          newAvatar: (payload) => {
            this.selectedImage =
              payload.url + payload.gender + payload.id + ".png";
          },
          newImage: (payload) => {
            console.log("P", payload);
            this.selectedImage = payload.url;
          },
        }
      );
    },
    hideModal(modal) {
      this.$modal.hide(modal);
    },
    showModal(modal) {
      this.$modal.show(modal);
    },
    setAddress(address) {
      this.address = address;
    },
    saveAddress() {
      this.showErrors = true;
      this.$forceUpdate();

      if (this.address && !this.address.lat) {
        this.$notify({
          message: "Please select an address",
          type: "danger",
        });
        return;
      }

      if (this.isInvalidForm()) {
        this.$notify({
          message: "Make sure all fields are filled",
          type: "warning",
        });
        return;
      }
      this.$store.dispatch("loading", true);
      ApiService.generalApi
        .saveAddress(this.address, this.$store.state.general.parent_id)
        .then(() => {
          this.submit();
        });
    },
    toggleJourneyPreference(type, field) {
      // let defaultJourneyPreference = 0;
      if (type === "parent") {
        // setTimeout(() => {
        if (field === "interestedInPooling") {
          if (this.parentData.interestedInPooling) {
            this.parentData.notInterestedInPooling = false;
          } else {
            if (this.parentData.travel_buddies) {
              this.parentData.notInterestedInPooling = false;
            } else {
              this.parentData.notInterestedInPooling = true;
            }
          }
        }
        if (field === "travel_buddies") {
          if (this.parentData.travel_buddies) {
            this.parentData.notInterestedInPooling = false;
          } else {
            if (this.parentData.interestedInPooling) {
              this.parentData.notInterestedInPooling = false;
            } else {
              this.parentData.notInterestedInPooling = true;
            }
          }
        }

        if (field === "notInterestedInPooling") {
          if (this.parentData.notInterestedInPooling) {
            this.parentData.journey_preference = 0;
            this.parentData.travel_buddies = false;
            this.parentData.interestedInPooling = false;
          }
        }
        // set journey preference:
        if (
          this.parentData.interestedInPooling &&
          !this.parentData.travel_buddies
        ) {
          this.parentData.journey_preference = 1;
        } else if (
          this.parentData.travel_buddies &&
          !this.parentData.interestedInPooling
        ) {
          this.parentData.journey_preference = 2;
        } else if (
          this.parentData.travel_buddies &&
          this.parentData.interestedInPooling
        ) {
          this.parentData.journey_preference = 3;
        }
      }
      if (type === "staff") {
        // setTimeout(() => {
        if (field === "interestedInPooling") {
          if (this.staffData.interestedInPooling) {
            this.staffData.notInterestedInPooling = false;
          } else {
            this.staffData.notInterestedInPooling = true;
          }
          this.staffData.journey_preference = 1;
        }

        if (field === "notInterestedInPooling") {
          if (this.staffData.notInterestedInPooling) {
            this.staffData.interestedInPooling = false;
          }
          this.staffData.journey_preference = 0;
        }
      }
      this.$forceUpdate();
    },
    getCurrentUserData() {
      this.$store.dispatch("loading", true);
      ApiService.userApi.getUserInfo().then((resp) => {
        this.$store.dispatch("loading", false);
        var data = resp.data;
        // var staffUser, parentUser, parent, staff;

        // if (data.user) {
        //   if (data.user.is_staff) {
        //     staffUser = data.user;
        //     staff = data.parent;
        //   }
        //   if (!data.user.is_staff) {
        //     parentUser = data.user;
        //     parent = data.parent;
        //   }
        // }

        // var userparent = !data.user.is_staff ? data.user : null;
        // var staff = data.user.is_staff ? data.user : null;

        if (data.address) {
          this.currentAddress = data.address;
          this.address = data.address;
          this.address.lat = data.address.latitude;
          this.address.lng = data.address.longitude;
        }

        this.schoolName = data.schoolName;
        this.selectedImage = data.profile_photo_url;

        data.roles.forEach((role) => {
          if (role.slug == "PARENT") {
            this.showParentOptions = true;
            this.parentData = role;
            if (this.parentData.journey_preference == 0) {
              this.parentData.notInterestedInPooling = true;
            } else if (this.parentData.journey_preference == 1) {
              this.parentData.interestedInPooling = true;
            } else if (this.parentData.journey_preference == 2) {
              this.parentData.travel_buddies = true;
            } else if (this.parentData.journey_preference == 3) {
              this.parentData.travel_buddies = true;
              this.parentData.interestedInPooling = true;
            }
          } else if (role.slug === "STAFF") {
            this.showStaffOptions = true;
            this.staffData = role;

            if (this.staffData.journey_preference == 0) {
              this.staffData.notInterestedInPooling = true;
            } else if (this.staffData.journey_preference == 1) {
              this.staffData.interestedInPooling = true;
            }

            this.getStaffSchoolAddress();
          }
        });

        // if (data.subUser) {
        //   if (data.subUser.is_staff) {
        //     staffUser = data.subUser;
        //     staff = data.subUserParent;
        //   }
        //   if (!data.subUser.is_staff) {
        //     parentUser = data.subUser;
        //     parent = data.subUserParent;
        //   }
        // }

        // if (parent) {
        //   this.showParentOptions = true;
        //   if (data.address && data.address.address_line_1) {
        //     this.parentData = parentUser;
        //     this.parentData.journey_preference = parent.journey_preference;
        //   } else {
        //     // this.parentData = {};
        //     // this.parentData.journey_preference = null;
        //   }
        // }

        // if (staff) {
        //   this.showStaffOptions = true;
        //   if (data.address) {
        //     this.staffData = staffUser;
        //     this.staffData.journey_preference = staff.journey_preference;
        //   } else {
        //     // this.staffData = {};
        //     // this.staffData.journey_preference = null;
        //   }
        // }

        // if (staff) {
        //   this.staffData = staffUser;
        //   this.staffData.journey_preference = staff.journey_preference;
        // }

        // if (data.address) {
        //   if (this.parentData) {
        //     if (this.parentData.journey_preference == 0) {
        //       this.parentData.notInterestedInPooling = true;
        //     } else if (this.parentData.journey_preference == 1) {
        //       this.parentData.interestedInPooling = true;
        //     } else if (this.parentData.journey_preference == 2) {
        //       this.parentData.travel_buddies = true;
        //     } else if (this.parentData.journey_preference == 3) {
        //       this.parentData.travel_buddies = true;
        //       this.parentData.interestedInPooling = true;
        //     }
        //     // this.parentData.is_pta = data.user.is_pta
        //   }

        //   if (staff) {
        //     if (this.staffData.journey_preference == 0) {
        //       this.staffData.notInterestedInPooling = true;
        //     } else if (this.staffData.journey_preference == 1) {
        //       this.staffData.interestedInPooling = true;
        //     }
        //   }

        //   // if (vm.staffData.is_staff) {
        //   //   vm.staffData.is_parent = vm.staffData.not_a_parent ? 0 : 1;
        //   // }

        //   // if (data.user.organization_address_id) {
        //   //   vm.staff.organization_address_id = data.user.organization_address_id
        //   // }

        //   // vm.sharing_journey = data.user.sharing_journey == 1 ? 1 : 0
        //   if (data.user.address) {
        //     this.postcode = data.address.postcode;
        //     this.defaultMarker = {
        //       lat: data.address.latitude,
        //       lng: data.address.longitude,
        //     };
        //     // this.currentAddress = data.address_line_1 + " " + data.postcode;
        //   }
        // }
        // if (staff) {
        //   this.getStaffSchoolAddress();
        // }
      });
    },
    getStaffSchoolAddress() {
      ApiService.generalApi.staffDestinationAddresses().then((response) => {
        if (response.data) {
          this.staffSchoolAddresses = response.data;
          if (this.staffSchoolAddresses.length == 1) {
            try {
              this.staffData.organization_address_id =
                this.staffSchoolAddresses[0].id;
            } catch (error) {
              alert(error);
            }
          }
        }
      });
    },
    submit() {
      var payloadData = {};
      this.showErrors = true;

      if (this.isInvalidForm()) {
        this.$store.dispatch("loading", false);
        return;
      }
      if (Object.keys(this.parentData).length) {
        payloadData.parentData = {};
        // payloadData.parentData.is_pta = parseInt(this.parentData.is_pta);
        if (
          this.parentData.travel_buddies &&
          this.parentData.interestedInPooling
        ) {
          payloadData.parentData.journey_preference = 3;
        } else if (this.parentData.travel_buddies) {
          payloadData.parentData.journey_preference = 2;
        } else if (this.parentData.interestedInPooling) {
          payloadData.parentData.journey_preference = 1;
        } else {
          payloadData.parentData.journey_preference = 0;
        }
        payloadData.parentData.sharing_journey = parseInt(
          this.parentData.sharing_journey
        );
      }

      if (Object.keys(this.staffData).length) {
        payloadData.staffData = {};
        payloadData.staffData.journey_preference = this.staffData
          .interestedInPooling
          ? 1
          : 0;
        payloadData.staffData.staff_type = this.staffData.staff_type;
        payloadData.staffData.sharing_journey = this.staffData.sharing_journey;
        payloadData.staffData.organization_address_id =
          this.staffData.organization_address_id;
      }
      ApiService.userApi
        .saveUserSettings(payloadData)
        .then(() => {
          if (payloadData.parentData) {
            this.$router.push({ name: "flowChildren" });
          } else {
            // Staff
            this.$router.push({ name: "journeys" });
          }
        })
        .catch(function (response) {
          alert(response);
        });
    },
    isInvalidForm() {
      const main_fields = ["selectedImage", "address"];

      let hasErrors = false;

      main_fields.forEach((field) => {
        if (this[field] === null) {
          hasErrors = true;
        }
      });

      if (this.showParentOptions) {
        const parents_fields = [
          "journey_preference",
          "sharing_journey",
          // "is_pta",
        ];
        parents_fields.forEach((field) => {
          if (
            this.parentData[field] === null ||
            this.parentData[field] === ""
          ) {
            hasErrors = true;
          }
        });
        if (
          !this.parentData.notInterestedInPooling &&
          !this.parentData.travel_buddies &&
          !this.parentData.interestedInPooling
        ) {
          hasErrors = true;
        }
        // todo
      }

      if (this.showStaffOptions) {
        const staff_fields = [
          // "staff_type",
          "journey_preference",
          "sharing_journey",
          "organization_address_id",
        ];
        staff_fields.forEach((field) => {
          if (this.staffData[field] == null) {
            hasErrors = true;
          }
        });
        if (
          !this.staffData.notInterestedInPooling &&
          !this.staffData.interestedInPooling
        ) {
          hasErrors = true;
        }
      }

      //

      return hasErrors;
    },
  },
  mounted() {
    this.$emit("progress-step", 2);

    if (this.$store.state.general.user.profile_photo_url) {
      this.selectedImage = this.$store.state.general.user.profile_photo_url;
    }
    this.getCurrentUserData();
    try {
      ApiService.generalApi.getYearLabels().then((resp) => {
        this.$store.dispatch("setYearLabels", resp.data);
      });
    } catch (error) {}
    const parent2Token = this.$store.state.general.parent2_token;
    if (parent2Token) {
      ApiService.userApi.getUserSchools(null, parent2Token).then(() => {
        this.$store.dispatch("addParent2Token", null);
      });
    }
  },
};
</script>

<style lang="scss" scoped>
$parent-primary-color: #99cc00;
$staff-primary-color: #33cccc;

p {
  font-size: 14px;
  // color: #979797;
}

label {
  font-weight: 500;
}

.radioInput {
  margin-left: 20px;
  width: 70px;
  // line-height: 35px;
  text-align: center;
}

.radioRow {
  // line-height: 55px;
  height: 53px;
  margin-left: 20px;

  .form-radio {
    margin-top: 0px;
    margin-bottom: -6px;
  }
}

h3.title {
  font-weight: 700;
  padding-left: 15px;
  padding-top: 10px;
  color: #ff0092;
  text-align: left;
  font-size: 14px !important;
}

.alert-mira {
  margin: 0 15px;
  margin-bottom: 15px;
}

.emptyProfileText {
  position: absolute;
  z-index: 9;
  top: 35px;
  left: 7px;
  font-weight: bold;
}

.form-radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  position: relative;
  background-color: #ffffff;
  color: $parent-primary-color;
  // top: 10px;
  height: 20px;
  width: 20px;
  margin-top: -3px;
  border: 1px solid #ccc;
  border-radius: 50px;
  cursor: pointer;
  margin-right: 15px;
  outline: none !important;
}

.form-radio:checked::before {
  position: absolute;
  font-weight: bold;
  font: 9px/1 "Open Sans", sans-serif;
  left: 4px;
  top: 4px;
  content: "\2B24";

  transform: rotate(40deg);
}

.form-radio:hover {
  background-color: #f7f7f7;
}

.form-radio:checked {
  // background-color: #f1f1f1;
}

.preferences {
  text-align: left;
}

.text-danger {
  text-align: center;
  color: #f4332f;
}

.preferences-row {
  display: flex;
  align-items: center;
  margin: 15px 0;

  div:first-child {
    flex: 0;
  }

  .m-chckbox--container {
    margin: 0 0.5rem 0 0;
  }
}

.staff-options__title {
  font-size: 20px;
  font-weight: 400;
  color: $staff-primary-color;
  line-height: 24px;
  text-align: left;
}

.staff-section {
  margin-top: 30px;
}
.form-dropdown-wrapper {
  position: relative;
}
.form-dropdown-wrapper:after {
  position: absolute;
  content: "";
  top: 10px;
  right: 10px;
  border: solid #000;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 5px;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
select:disabled {
  color: gray;
}
</style>
