<template>
  <modal
    class="modal-flow"
    name="editModal"
    id="modalOverlay"
    :adaptive="true"
    height="auto"
    :clickToClose="false"
    data-cy="edit_child_modal"
  >
    <div>
      <div class="col-xs-12">
        <ModalHeader
          v-if="!child.id"
          title="Add Child"
          @hideModal="$emit('confirm-cancel')"
          :flow="true"
          data-cy="header_add_child"
        />
        <ModalHeader
          v-if="child.id"
          title="Edit a Child"
          @hideModal="$emit('confirm-cancel')"
          :flow="true"
          data-cy="header_edit_child"
        />
      </div>
      <div
        class="col-xs-12"
        style="display: flex; flex-direction: row; align-items: center"
      >
        <span class="avatar-label van-cell__title van-field__label"
          >Select an Avatar</span
        >
        <div
          class="avatar"
          @click="$emit('change-picture')"
          data-cy="change_avatar_btn"
          style="display: inline-block"
        >
          <span class="add-icon" v-if="!child.imageUrl && !child.profile_photo"
            ><i class="fa fa-plus text-sm" aria-hidden="true"></i
          ></span>

          <img
            v-if="child.profile_photo && !child.imageUrl"
            :src="s3url + child.profile_photo"
            :alt="child.profile_photo"
            data-cy="child_profile_photo"
          />
          <img
            v-if="child.imageUrl"
            :src="child.imageUrl"
            :alt="child.profile_photo"
            data-cy="child_profile_ptoto"
          />
          <span
            v-if="!child.imageUrl && !child.profile_photo"
            data-cy="show_empty_avatar"
            style="
              color: #e61180;
              font-size: 11px;
              position: relative;
              display: inline-block;
            "
          >
            <i class="user-icon fa fa-user-circle" />
          </span>
        </div>

        <div
          class="col-xs-12 text-danger"
          v-if="!child.imageUrl && !child.profile_photo && showErrors"
          data-cy="no_avatar_error_message"
        >
          Avatar/Image is required
        </div>
      </div>
      <div class="col-xs-12 marginTop15">
        <div class="van-cell">
          <span class="van-cell__title van-field__label">First Name</span>
          <input
            type="text"
            class="van-field__control van-cell__value"
            v-model="child.first_name"
            data-cy="first_name_input"
            :class="{ errorInput: !child.first_name && showErrors }"
          />
        </div>
      </div>
      <div class="col-xs-12">
        <div class="van-cell">
          <span class="van-cell__title van-field__label">Last Name</span>
          <input
            type="text"
            class="van-field__control van-cell__value"
            v-model="child.last_name"
            data-cy="last_name_input"
            :class="{ errorInput: !child.last_name && showErrors }"
          />
        </div>
      </div>

      <div class="col-xs-12">
        <div class="van-cell van-cell--block">
          <span class="van-cell__title">Select from your Schools</span>
          <div class="form-select-wrapper marginTop5">
            <select
              class="form-select van-cell__value colorBlack"
              v-model="child.organization_id"
              data-cy="school_selection_input"
              placeholder="School"
              :class="{ errorInput: !child.organization_id && showErrors }"
            >
              <option
                :key="option.id"
                v-for="option in schools"
                :value="option.id"
              >
                {{ option.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="col-xs-12 marginTop5">
        <div class="van-cell">
          <span class="van-cell__title">Current year</span>
          <div class="van-cell__value">
            <div class="form-select-wrapper">
              <select
                class="form-select van-field__control"
                v-model="child.current_year"
                data-cy="current_year_selection_input"
                placeholder="Current year"
                :class="{
                  errorInput: child.current_year === null && showErrors,
                }"
              >
                <option
                  :key="option.id"
                  v-for="option in schoolYears"
                  :value="option.level"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="col-xs-12 marginTop5">
        <div class="van-cell van-cell--block">
          <span class="van-cell__title"
            >Does your child receive travel support from the local
            authority?</span
          >
          <div class="van-cell__value">
            <div class="form-select-wrapper marginTop5">
              <select
                class="form-select van-cell__value colorBlack"
                v-model="child.sen"
                placeholder="Does your child receive travel support from the local authority?"
                data-cy="sen_selection_input"
                :class="{
                  errorInput: child.sen === '' && showErrors,
                }"
              >
                <option
                  :key="option.value"
                  v-for="option in senOptions"
                  :value="option.value"
                >
                  {{ option.name }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div> -->
      <div class="col-xs-12 form-action-row">
        <button
          class="btn greyBtn van-button van-button--info van-button--normal van-button--plain"
          @click="$emit('confirm-remove')"
          data-cy="remove_child_btn"
        >
          Remove Child
        </button>
        <button
          class="btn btn-black"
          :disabled="loading"
          @click="$emit('save-child')"
          data-cy="save_child_btn"
        >
          Continue
        </button>
      </div>
    </div>
  </modal>
</template>
<script>
import ModalHeader from "@/components/ModalHeader";

export default {
  data() {
    return { loading: false };
  },
  watch: {
    child: {
      handler(val) {
        this.$forceUpdate();
      },
      deep: true,
    },
  },
  props: {
    child: {
      type: Object,
      default: () => {},
    },
    s3url: {
      type: String,
      default: "",
    },
    senOptions: {
      type: Array,
      default: () => [],
    },
    showErrors: {
      type: Boolean,
      default: false,
    },
    schools: {
      type: Array,
      default: () => [],
    },
    schoolYears: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    ModalHeader,
  },
};
</script>
<style scoped>
.avatar-label {
  width: 104px !important;
  display: inline-block;
  flex: none !important;
}
.user-icon.fa-user-circle {
  position: absolute;
  top: 10px;
  left: 26px;
}
</style>
