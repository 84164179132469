<template>
  <div ref="mainCont" class="px-3 md:px-0 pb-20">
    <div class="bg-white rounded-lg shadow mt-0 mb-5 py-2">
      <div class="mt-1 md:mt-2 pt-0 px-2 md:px-0 md:mx-3">
        <div class="flex flex-col items-center text-center">
          <div class="text-xl text-black">School Travel Options</div>

          <p class="text-sm text-chat my-2 w-full md:w-2/3">
            Access your school run options below and create a greener, healthier
            school community.
          </p>
        </div>
      </div>
    </div>
    <div>
      <TravelFeature
        :key="feature.id"
        v-for="feature in features"
        :native="native"
        @action="action"
        @toggled="toggled(feature.category)"
        :isStaff="is_staff"
        :item="feature"
        :disabled="is_staff && feature.parentOnly"
        :ref="feature.category"
      />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import TravelFeature from "@/components/TravelFeature.vue";

import TransportPoll from "@/components/modals/TransportPoll";
import TransportSuggest from "@/components/modals/TransportSuggest";
import ApiService from "@/services/ApiService";

export default {
  name: "featuresSchoolrun",
  components: {
    TravelFeature,
  },
  data() {
    return {
      // features: [],
      areas: {},
      councils: {},
      schools: {},
      openedIndex: null,
    };
  },
  computed: {
    native() {
      return this.$store.state.general.native;
    },
    currentUserRoleId: function () {
      return this.$store.state.general.userRoleId;
    },
    is_staff() {
      return this.roleType == "STAFF" ? 1 : 0;
    },
    roleType: function () {
      return this.$store.state.general.roles.find(
        (role) => this.currentUserRoleId == role.user_role_id
      )?.slug;
    },
    features() {
      if (this.is_staff) {
        const result = this.$store.state.general.schoolrunFeatures
          .slice()
          // .filter((itm) => (!this.is_staff ? itm.is_staff != 1 : itm))
          .sort((a, b) => a.parentOnly - b.parentOnly);
        return result;
      } else {
        const result = this.$store.state.general.schoolrunFeatures
          .slice()
          .filter((itm) => itm.staffOnly != 1)
          .sort((a, b) => a.staffOnly - b.staffOnly);
        return result;
      }
    },
  },
  methods: {
    action(params) {
      if (params.action === "show_poll") {
        this.showModal({
          category: params.category,
          modal_type: params.modal_type,
          explanation: params.explanation,
          color: params.color,
        });
      } else if (params.action === "redirectJourney") {
        this.redirectJourney();
      }
    },
    showModal(params) {
      const modalToShow =
        params.modal_type == 2 ? TransportPoll : TransportSuggest;
      this.$modal.show(
        modalToShow,
        {
          params: {
            category: params.category,
            explanation: params.explanation ? params.explanation : "",
          },
        },
        {
          draggable: false,
          transition: "slide-fade",
          scrollable: true,
          height: "auto",
          classes: `travel-modal font-rubik`,
          styles: { backgroundColor: params.color ? params.color : "#ff8956" },
        },
        {}
      );
    },
    change() {
      this.menuChoice = this.value ? "parent" : "staff";
    },
    redirectTo(routeName, param) {
      if (param) {
        this.$router.push({ name: routeName, params: { type: param } });
      } else {
        this.$router.push({ name: routeName });
      }
    },
    redirectJourney() {
      if (this.$store.state.general.native == true) {
        var version = parseInt(
          String(this.$store.state.general.version).split(".")[0]
        );
        if (version >= 7) {
          window.postMessage("showJourneys", "*");
        } else {
          this.$dialog.alert({
            title: "Independent Travel",
            message:
              "To use this feature, please update your App to the latest version from the App Store or Play Store.",
          });
        }
      } else {
        this.$dialog.alert({
          title: "Independent Travel",
          message: "This feature is only available on the smartphone app",
        });
        // alert("This feature is only available on the smartphone app");
        // _parent.goto("main.trackMyChildren", {childId: student_id}, "main.home");
      }
    },
    formatFeatures(data) {
      const categories = data
        .filter((i) => !i.section_id)
        .map((itm) => {
          itm.blocks = [];
          return itm;
        })
        .sort((a, b) => {
          return a.order - b.order;
        });
      const items = data.filter((i) => i.section_id >= 0);

      items.forEach((element) => {
        let image = "";
        if (
          !element.school_id &&
          !element.council_id &&
          !element.area_id &&
          !element.image
        ) {
          image = "/img/homerun-logo-round.png";
        } else if (element.image && element.image.path) {
          image = element.image.path;
        } else if (element.school_id) {
          image = this.schools[element.school_id].image;
        } else if (element.council_id) {
          image = this.councils[element.council_id].image;
        } else if (element.area_id) {
          if (this.areas[element.area_id]) {
            image = this.areas[element.area_id].logo;
          } else {
            image = "/img/homerun-logo-round.png";
          }
        }
        if (element.link && element.link.substring(0, 4) != "http") {
          element.link = "https://" + element.link;
        }

        element.image = image;
        let index = categories
          .map(function (e) {
            return e.id;
          })
          .indexOf(element.section_id);
        if (index > -1) {
          if (
            !categories[index].blocks ||
            categories[index].blocks.length === 0
          ) {
            categories[index].blocks = [];
            categories[index].bgColor = this.convertHex(
              categories[index].color,
              0.1
            );
            categories[index].borderColor = this.convertHex(
              categories[index].color,
              0.25
            );
          }
          categories[index].blocks.push(element);
        }
      });
      const newData = this.features.length > 0 ? false : true;
      this.$store.dispatch("updateSchoolrunFeatures", categories);
      if (newData) {
        this.$nextTick(() => {
          this.$refs[this.features[0].category][0].toggle();
        });
      }
    },
    convertHex(hexCode, opacity = 1) {
      var hex = hexCode.replace("#", "");

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      var r = parseInt(hex.substring(0, 2), 16),
        g = parseInt(hex.substring(2, 4), 16),
        b = parseInt(hex.substring(4, 6), 16);

      /* Backward compatibility for whole number based opacity values. */
      if (opacity > 1 && opacity <= 100) {
        opacity = opacity / 100;
      }

      return "rgba(" + r + "," + g + "," + b + "," + opacity + ")";
    },
    toggled(category) {
      if (this.openedCategory != category && this.openedCategory != null) {
        setTimeout(() => {
          this.$refs[this.openedCategory][0].close();
          this.openedCategory = category;
        }, 0);
      } else {
        this.openedCategory = category;
      }
      // // scroll to
      // setTimeout(() => {
      //   const element = this.$refs[category][0].$el;
      //   window.scrollTo(0, element.offsetTop);
      // }, 100);
    },
  },
  created() {
    ApiService.generalApi.getTravelFeatures().then((resp) => {
      this.schools = resp.data.schools.reduce(
        (obj, item) => Object.assign(obj, { [item.id]: { ...item } }),
        {}
      );
      // this.councils = resp.data.councils.reduce(
      //   (obj, item) => Object.assign(obj, { [item.id]: { ...item } }),
      //   {}
      // );
      // this.areas = resp.data.areas.reduce(
      //   (obj, item) => Object.assign(obj, { [item.id]: { ...item } }),
      //   {}
      // );
      this.formatFeatures(resp.data.features);
    });
  },
  mounted() {
    // this.$nextTick(() => {
    setTimeout(() => {
      if (this.features && this.features.length > 0) {
        this.$refs[this.features[0].category][0].toggle();
      }
    }, 200);
    // });
  },
  beforeDestroy() {
    // this.animate = false;
  },
};
</script>
<style>
div.travelFeaturesPage {
  background: white;
}

.travel-modal {
  @apply rounded-2xl py-4 px-6 text-white;
  width: 95% !important;
}

.travel-explanation {
  @apply font-medium text-center mt-3 m-auto w-full md:w-3/4;
  font-size: 14px;
}

.travel-disclaimer {
  @apply text-xs font-medium text-center m-auto mb-1 w-3/4;
}

.modal-header {
  @apply text-lg font-medium;
}
</style>
