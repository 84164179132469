<style lang="scss" scoped>
$primary-color: #9cc200;
$secondary-color: #008a93;

.wrapper {
  height: 100%;
  font-family: "Rubik", sans-serif;
}

.v--modal {
  border-radius: 8px !important;

  @media screen and (max-width: 767px) {
    width: 85% !important;
  }
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  padding: 15px 0 0;
  border-top: 0 !important;
}

.primary-text {
  color: $primary-color;
  font-weight: 500;
}

.action-buttons {
  display: flex;
  flex-direction: column;
  max-width: 310px;
  margin: 20px auto;

  @media screen and (max-width: 767px) {
    margin: 15px 0;
  }

  button {
    margin-bottom: 20px;

    &:hover {
      opacity: 0.9;
    }
  }
}

.btn-primary {
  background: $primary-color;
  border-radius: 20px;
  border: 1px solid $primary-color;
  padding: 10px 30px;
  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
}

.staff-edition {
  .btn-primary {
    background: $secondary-color;
    border-color: $secondary-color;
  }
}

div.shadow {
  border: none;
  -webkit-box-shadow: 8px 9px 18px -6px rgba(122, 122, 122, 1);
  -moz-box-shadow: 8px 9px 18px -6px rgba(122, 122, 122, 1);
  box-shadow: 8px 9px 18px -6px rgba(122, 122, 122, 1);
}

#parentModal {
  padding: 30px 20px;
}

.main {
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 100vh;
  padding: 25px;
}

.header {
  display: flex;
  align-items: flex-end;
  padding: 20px 0;

  img {
    border-radius: 50px;
    max-height: 74px;
    width: 76px;
    height: 74px;
    margin: 0;
  }

  @media screen and (min-width: 767px) {
    flex-direction: column;
    align-items: center;
    padding: 20px 0 40px;
  }
}

.content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  @media screen and (min-width: 767px) {
    align-items: center;
  }
}

.middle-content {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: space-between;

  @media screen and (min-width: 767px) {
    margin: 0 auto;
    justify-content: flex-start;
    text-align: center;
  }
}

.btn:hover {
  color: #ffffff;
}

.school-name {
  color: #000000;
  margin-left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 14px;

  @media screen and (min-width: 767px) {
    font-weight: 500;
    margin: 15px 0 0;
  }
}

.welcome-text {
  font-weight: 400;
  font-size: 50px;
  color: #fff;
  margin: 5px 0;
  line-height: 60px;

  @media screen and (min-width: 767px) {
    font-size: 60px;
    margin: 0 auto 25px;
    text-align: center;
    line-height: 71px;
  }

  span {
    display: block;
  }
}

.edition-title {
  font-weight: 400;
  font-size: 26px;
  line-height: 31px;
  letter-spacing: 0.02em;
  color: #556a00;
}

.alert.alert-info {
  background: #ffffff;
  border-radius: 50px;
  margin: 30px 0;
  padding: 7px 40px;
  font-weight: 400;
  font-size: 11px;
  color: #605e5e;
  text-align: center;
  letter-spacing: 0.02em;

  @media screen and (min-width: 767px) {
    margin: 0;
  }
}

.parent-edition {
  background: url(~@/assets/launch/parent-bg.png) no-repeat;
  background-size: cover;
}

.staff-edition {
  background: url(~@/assets/launch/staff-bg.png);
  background-size: cover;

  .primary-text,
  .edition-title {
    color: #00779e;
  }
}

.logo {
  margin: 0;

  @media screen and (min-width: 767px) {
    margin: 40px auto 10px;
  }
}
</style>
<template>
  <div class="wrapper">
    <div id="mainDiv" class="main parent-edition" v-if="data.name && !isStaff">
      <div v-if="codeExists" class="middle-content">
        <div id="topLogo" class="header">
          <img :src="data.logo_url" alt />
          <p class="school-name">
            {{ data.name }}
          </p>
        </div>
        <div class="content">
          <div v-if="!inviteDetails">
            <p class="welcome-text" data-cy="welcome-text">
              Welcome<br />to HomeRun
            </p>
            <span class="edition-title" data-cy="parent-edition-text"
              >Parent Edition</span
            >
          </div>
          <div
            v-if="inviteDetails"
            class="alert alert-mira text-center shadow mb-5"
            style="font-size: 14px; margin: 20px 0; line-height: 21px"
          >
            <strong>{{ inviteDetails.parent_name }}</strong> has invited you to
            add the <br />following
            <strong>Children</strong>
            to your profile.
            <hr style="margin: 10px" />
            <span
              class="primary-text"
              :key="child.id"
              v-for="(child, index) in inviteDetails.children"
              ><span v-if="index !== 0">, </span>{{ child }}</span
            >
            <hr style="margin: 10px" />
            <p>To accept the invite, simply click one from below:</p>
          </div>
          <div class="action-buttons">
            <button class="btn-black" @click="showModal" data-cy="new-acc-btn">
              I am new to HomeRun
            </button>
            <button
              class="btn-primary"
              @click="next('existing')"
              data-cy="existing-acc-btn"
            >
              I already have a HomeRun account
            </button>

            <div class="alert alert-info">
              You can only use this link if you have children who attend the
              above school.
            </div>
          </div>
        </div>

        <inline-svg :src="require('@/assets/flow/logo.svg')" class="logo" />
      </div>
    </div>
    <div id="mainDiv" class="main staff-edition" v-if="data.name && isStaff">
      <div class="middle-content">
        <div id="topLogo" class="header">
          <img data-cy="school-logo" :src="data.logo_url" alt />
          <p class="school-name" data-cy="school-name">
            {{ data.name }}
          </p>
        </div>
        <div class="content">
          <div>
            <p class="welcome-text" data-cy="welcome-text">
              <span>Welcome</span><span>to HomeRun</span>
            </p>
            <span class="edition-title" data-cy="staff-edition-text"
              >Staff Edition</span
            >
          </div>

          <div class="action-buttons">
            <button
              class="btn-black"
              @click="next('new')"
              data-cy="new-acc-btn"
            >
              I am new to HomeRun
            </button>

            <button
              class="btn-primary"
              @click="next('existing')"
              data-cy="existing-acc-btn"
            >
              I already have a HomeRun account
            </button>
            <div class="alert alert-info">
              You must be a member of staff of <br />the above school to use
              this link.
            </div>
          </div>
        </div>
        <inline-svg :src="require('@/assets/flow/logo.svg')" class="logo" />
      </div>
    </div>
    <modal name="parentModal" :adaptive="false" :height="'auto'">
      <div id="parentModal">
        <p>
          If you wish to link your child's profile with your partner, only
          <span class="primary-text">
            ONE parent should create their account using this link.</span
          >
          Then you can <span class="primary-text">INVITE</span> the second
          parent from the app. <br />You can also choose to have entirely
          separate accounts if preferred.
        </p>
        <div class="modal-footer">
          <button data-cy="modalHide" @click="hideModal" class="btn-grey">
            Cancel
          </button>
          <button
            @click="next('new')"
            class="btn-primary pull-right"
            data-cy="continueNewParentButton"
          >
            Continue
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ApiService from "@/services/ApiService";

export default {
  name: "schoollink",
  components: {},
  data() {
    return {
      data: {},
      staffCode: "",
      codeExists: null,
      isStaff: false,
      inviteDetails: null,
      code: null,
    };
  },
  methods: {
    showModal() {
      if (this.inviteDetails) {
        this.next("new");
      } else {
        this.$modal.show("parentModal");
      }
    },
    hideModal() {
      this.$modal.hide("parentModal");
    },
    checkCode(code) {
      ApiService.generalApi
        .check_school_code(code)
        .then((response) => {
          this.code = code;
          if (response.status == 200 && response.data.status != 0) {
            this.codeExists = true;
            if (response.data.staff == true) {
              this.isStaff = true;
            }
            this.data = response.data;
          } else {
            this.codeExists = false;
          }
        })
        .catch(function () {
          // vm.error = response.data.error.message;
          // vm.loading = false;
        });
    },
    next(action) {
      const school_code = this.$route.params.schoolCode;
      this.$store.dispatch("addSchoolCode", school_code);
      if (action == "existing") {
        // store.set("skipCongratulations", true);
        if (this.$store.state.general.token) {
          ApiService.generalApi.addSchool(school_code).then((response) => {
            if (response.status == 200) {
              ApiService.generalApi.logout();
              if (this.$route.query && this.$route.query.token) {
                this.$store.dispatch(
                  "addParent2Token",
                  this.$route.query.token
                );
              }
              setTimeout(() => {
                this.$router.push({
                  name: "login",
                  query: { existing: "true", school_name: this.data.name },
                });
              }, 200);
            }
          });
        } else {
          this.$notify({
            message: "Please login to your account to continue",
            type: "warning",
          });
          this.$router.push({
            name: "login",
            query: { existing: "true", school_name: this.data.name },
          });
        }
      } else if (action == "new") {
        ApiService.generalApi.logout();
        if (this.$route.query && this.$route.query.token) {
          this.$store.dispatch("addParent2Token", this.$route.query.token);
        }
        this.$router.push("/flow/register");
      }
    },
  },
  mounted() {
    this.checkCode(this.$route.params.schoolCode);

    if (this.$route.query.token) {
      ApiService.userApi
        .inviteDetails(this.$route.query.token)
        .then((response) => {
          this.inviteDetails = response.data;
          if (this.$route.query && this.$route.query.token) {
            this.$store.dispatch("addParent2Token", this.$route.query.token);
          }
        });
    }
  },
};
</script>
