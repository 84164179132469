<style lang="scss">
.imageDiv {
  width: 50px !important;
  height: 40px;
}
.blockTitle {
  position: relative;
}

.notice-avatar-container {
  width: 48px;
  min-height: 38px;
  min-width: 48px;
}

.notice-avatar {
  position: absolute;
  width: 40px;
  height: 40px;
  background: #eee;
  border-radius: 60px;
  margin-right: 8px;
  padding: 0px;
  line-height: initial;
  object-fit: contain;
  transition: transform 0.3s, margin 0.3s;
  z-index: 99;
}

.notice-avatar.sm {
  width: 28px;
  height: 28px;
}

img.imgexpanded {
  transform: scale(2.5);
  margin: 22px 0 0 22px;
  z-index: 999;
}

.notice-title {
  @apply flex items-start pb-1;

  i {
    line-height: inherit;
  }
}

.notice-user-details {
  @apply flex flex-col;

  div {
    @apply flex items-start lg:items-center;
  }
}

.label-new {
  @apply rounded-2xl px-2 font-medium text-white ml-4;
  box-shadow: 0px 2.56761px 6.41904px rgba(0, 0, 0, 0.15);
  font-size: 8px;
  position: absolute;
  right: 95px;
  top: 12px;
}

.username {
  @apply text-black font-medium;
  font-size: 15px;
  min-width: 100px;
  margin-bottom: 3px
}

.user-type {
  @apply text-xs text-black font-medium;
}

.time-ago {
  @apply text-xs ml-2;
  color: #999797;
}

.notice-body {
  font-size: 13px;
  color: #6c6c6c;
}
</style>
<template>
  <div class="notice-title">
    <div
      class="notice-avatar-container"
      v-if="
        message.user_type === 3 ||
        message.user_type === 21 ||
        message.user_type === 22
      "
    >
      <img
        v-if="message.user_type === 3"
        @click.stop="toggleExpand"
        :class="expanded ? 'imgexpanded' : ''"
        class="notice-avatar border-notice-theme"
        :src="message.imageUrl"
      />
      <img
        v-if="message.user_type != 3"
        @click.stop="toggleExpand"
        :class="expanded ? 'imgexpanded' : ''"
        class="notice-avatar border-notice-theme"
        :src="message.imageUrl"
      />
    </div>

    <i
      v-if="message.typeIcon"
      class="fas fa-lg notice-avatar relative flex items-center justify-center"
      :class="'fa-' + message.typeIcon"
    ></i>
    <img
      class="notice-avatar border-notice-theme relative"
      v-if="message.user_type == 5"
      src="@/assets/logo-small-black.png"
    />

    <div
      class="notice-avatar-container"
      v-if="
        message.user_type == 6 ||
        message.user_type == 7 ||
        message.message_tag == 'chat'
      "
      :class="{ expanded: expanded == message.id }"
    >
      <img
        @click.stop="toggleExpand"
        :src="message.parent_photo"
        class="notice-avatar border-notice-theme"
        :class="expanded ? 'imgexpanded' : ''"
      />
    </div>
    <div class="notice-user-details">
      <div class="mr-2">
        <span
          class="username mr-7"
          v-if="message.user_type != 8 && message.user_type != 9"
          >{{ message.from_label || message.name || "" }}</span
        >

        <span
          class="safeguardTitle"
          v-if="message.type === 'BG_JOURNEY' && !messengerMode"
          >Monitoring: {{ message.mode }}
        </span>
        <span
          class="username"
          v-if="message.user_type == 8 || message.user_type == 9"
          >{{ message.salutation + " " + message.last_name || "" }}</span
        >
        <span
          v-if="message.important"
          style="display: inline-block; padding-left: 5px"
        >
          <i class="fas fa-star colorTrump"></i>
        </span>
        <span
          v-if="message.is_new && !messengerMode"
          class="label-new bg-notice-theme"
          >NEW</span
        >
      </div>

      <div v-if="messengerMode">
        <span v-if="!message.is_staff" class="text-xs text-pink font-medium"
          >Parent</span
        >
        <span
          v-if="message.is_staff"
          class="text-xs text-purple-light font-medium"
          >Teacher</span
        >
      </div>

      <div>
        <div v-if="!messengerMode">
          <span class="user-type" v-if="message.typeLabel">
            {{ message.typeLabel }}
          </span>
          <span class="user-type" v-if="message.message_tag === 'chat'">
            {{ message.is_staff ? "Private Chat from School" : "Private Chat" }}
          </span>
        </div>

        <div v-if="messengerMode">
          <!-- <span>{{ message.user }}</span> -->
          <!-- <div
            class="notice-body px-0 py-0 pt-0.5 m-0 text-chat border-0"
            v-if="message.message != '<p></p>' && message.message != ''"
          >
            <p v-html="message.message"></p>
          </div> -->

          <span class="time-ago absolute top-2 right-0">
            <timeago :datetime="message.created_timestamp * 1000"></timeago>
          </span>
        </div>

        <span class="time-ago" v-else>
          <timeago :datetime="message.created_timestamp * 1000"></timeago>
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "blockTitle",
  props: {
    message: Object,
    messengerMode: Boolean,
    noHiding: String,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleExpand() {
      this.expanded = !this.expanded;
    },
    hideNotice() {
      this.$emit("noticeEvent", "hideNotice");
    },
    showNewEditPostModal() {
      this.$emit("noticeEvent", "editNotice");
    },
  },
};
</script>
